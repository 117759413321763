import Bowl from '../asset/img/bowl.png';
import Floatlogo from '../asset/img/two_circle.png';


function Home() {
   
return (
    <div className="h-screen md:h-[calc(100vh-175px)] overflow-auto font-Inter flex items-center">      
            <div className="flex flex-col lg:flex-row md:flex-col justify-between box-content">

                <div className="w-full bg-cover mb-12 md:mb-8 lg:mb-0 relative max-w-md lg:max-w-2xl lg:w-7/12">
                    <div className="flex flex-col items-center justify-center w-2/3 md:w-4/5 lg:w-full md:ml-52 mx-auto md:mx-auto lg:mx-0 h-full relative lg:py-10 box-content">
                        <img src={Bowl} alt="img" />
                    </div>
                </div>

                <div className="w-full lg:w-2/5 md:w-4/5 h-full my-0 md:my-4 lg:my-2 mx-auto px-8 md:px-12 py-8 md:py-16 bg-white shadow-2xl rounded-2xl relative z-10 dark:bg-gray-600 dark:text-white ">
                    <h1 className="w-full pb-2 text-4xl font-medium text-center leading-snug font-Centurygothic">NEW TIBET SOUVENIRS</h1>
                
                    <p className="w-full inline-block pt-4 pr-5 pb-4 pl-5 text-lg font-medium text-gray-600 text-center dark:text-white ">Bienvenue dans notre petite boutique tibétaine nichée au cœur de Paris ! Nous sommes fiers de vous faire découvrir une touche de l'enchantement de la culture tibétaine à travers notre collection exquise d'arts et d'accessoires.</p>
                </div>
            </div>
               
            <svg viewbox="0 0 91 91" className="hidden lg:block absolute top-60 right-56 z-0 w-32 h-32 text-logogray
             fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
            cx="38" cy="38" r="38"/></g></g></g></g></svg>
            <img src={Floatlogo} alt="floating logo bg" className='absolute bottom-16 right-0 z-0 w-36' />
        </div>
    )
};
export default Home;