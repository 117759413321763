import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/navbar';
import Home from './views/home';
import About from './views/about';
import Product from './views/product';
import Faq from './views/faq';
import Contact from './views/contact';
import Footer from "./components/footer";

function App() {
  return (
    <div className="scroll-smooth">
        <Router>
        <Navbar/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/product" element={<Product />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
