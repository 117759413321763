

function Cards(props)  {
 return (
    <div className="mx-auto p-1 w-60 bg-logoblue rounded-lg shadow-sm">
        <img className=" w-full object-cover object-center rounded-md" src={props.cardImg} alt="Product" />    
    </div>

 )
}
export default Cards;