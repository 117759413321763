import Floatlogo from '../asset/img/two_circle.png';
import shop from '../asset/img/shop.jpg';

function About() {
   
    return (
        <div className="my-4 font-Inter">            
            <svg viewbox="0 0 91 91" className="hidden md:block absolute opacity-70 z-0 top-12 right-2 md:left-1/3 md:top-24 w-32 h-24 text-logogray fill-current ml-44"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
                   cx="38" cy="38" r="38"/></g></g></g></g></svg>

            <div className="flex flex-col md:flex-col lg:flex-row justify-between box-content ">

                <div className="w-full md:w-4/5 lg:w-2/5 h-full my-6 mx-auto px-8 md:px-12 py-8 md:py-28 bg-white shadow-2xl rounded-xl relative z-10 dark:bg-gray-900 ">
                 
                   <h1 className=" text-3xl font-semibold leading-none mb-4 text-gray-600 dark:text-white">A PROPOS DE NOUS</h1>
              
                   
                    <p className="max-w-4xl mx-auto mb-4 font-medium text-gray-600 lg:mb-6 text-md dark:text-gray-400">
                    Nous sommes un couple tibétain passionné, profondément intéressé par notre riche patrimoine culturel. Notre objectif est de partager avec vous l'essence de notre culture.
                    </p>
                
                    <p className="max-w-4xl mx-auto mb-4 font-medium text-gray-600 lg:mb-6 text-md dark:text-gray-400">
                    En tant qu'artisans à l'origine de cette entreprise, j'ai plus de 15 ans d'expérience dans l'artisanat du bois. J'ai affiné mes compétences au fil des ans, maîtrisant l'art de travailler le bois pour créer des pièces uniques et complexes qui reflètent l'esprit du Tibet.
                    </p>
                    <p className="max-w-3xl mx-auto mb-4 font-medium text-gray-600 lg:mb-6 text-md dark:text-gray-400">
                    Nous vous invitons à parcourir notre collection, à toucher et à ressentir les textures des sculptures en bois, et à trouver une pièce qui résonne avec votre âme. Que vous recherchiez un cadeau significatif, un objet de décoration pour la maison ou un article personnel pour enrichir votre pratique spirituelle, nous sommes là pour vous guider et partager les histoires et le symbolisme derrière chaque création.
                    </p>
                    <p className="max-w-4xl mx-auto font-medium text-gray-600 text-md dark:text-gray-400 ">
                    Nous vous accueillons chaleureusement dans notre petit coin de Paris, où nous nous efforçons non seulement de mettre en valeur l'artisanat de la culture tibétaine, mais aussi de créer un espace de connexion et d'appréciation. Merci de vous joindre à nous dans cette aventure pour célébrer et préserver la beauté de notre cher Tibet.</p>
                   
                </div>

                <img src={shop} alt="Shop front" className="w-[42rem] md:w-[42rem]" />
            </div>
                <img src={Floatlogo} alt="floating logo bg" className='hidden lg:block relative opacity-60 bottom-8 left-12 z-0 w-32 h-auto' />
                
                   {/* We are proud to bring you a touch of the enchanting Tibetan culture through our exquisite collection of arts and accessories.

                   At our shop, you will discover a treasure trove of authentic Tibetan singing bowls and we offer a diverse range of accessories that reflect the rich heritage of Tibetan craftsmanship. Delicate prayer flags, intricately designed jewelry, and traditional Tibetan incense are just a few of the items you will find here. Each piece has been carefully selected to capture the essence of Tibetan art and spirituality.

                   By choosing to shop with us, you not only bring home a piece of Tibet but also contribute to the empowerment and preservation of this remarkable culture.

                   We look forward to welcoming you and sharing the wonders of Tibet with you at our little corner in Paris. Embrace the tranquility, embrace the beauty, and embrace the spirit of Tibet with us. 
                   
                   Welcome to our small Tibetan shop in Paris! We are a passionate Tibetan couple deeply interested in our rich cultural heritage. Our aim is to share the essence of our culture with you.

                   As the craftsmen behind this endeavor, I have more than 15 years of experience in woodcraft. I have honed my skills over the years, mastering the art of working with wood to create unique and intricate pieces that reflect the spirit of Tibet.

                   We believe that every item in our shop carries a piece of our culture's soul. From meticulously carved wooden statues to carefully crafted prayer beads, each piece is infused with the love, dedication, and artistry that we pour into our work. We take great pride in preserving traditional Tibetan craftsmanship and ensuring that each item we offer represents the authenticity and beauty of our culture.

                   We invite you to browse through our collection, to touch and feel the textures of the wooden carvings, and to find a piece that resonates with your spirit. Whether you are seeking a meaningful gift, a piece of home decor, or a personal item to enhance your spiritual practice, we are here to guide you and share the stories and symbolism behind each creation.

                   We warmly welcome you to our little corner of Paris, where we strive to not only showcase the craftsmanship of Tibetan culture but also to create a space of connection and appreciation. Thank you for joining us on this journey to celebrate and preserve the beauty of our beloved Tibet.*/}
        </div>
        
    )
};
export default About;