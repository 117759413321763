import Cards from '../components/cards';
import Carousel1 from '../components/carousel';
import Floatlogo from '../asset/img/two_circle.png';
import product from '../asset/img/v1.jpg';
import product1 from '../asset/img/v2.jpg';
import product2 from '../asset/img/v3.jpg';
import product3 from '../asset/img/v4.jpg';
import product4 from '../asset/img/v5.jpg';
import product5 from '../asset/img/v6.jpg';
import product6 from '../asset/img/v7.jpg';
import product7 from '../asset/img/v8.jpg';
import product8 from '../asset/img/v9.jpg';
import product9 from '../asset/img/v10.jpg';
import product10 from '../asset/img/20.jpg';
import product11 from '../asset/img/21.jpg';
//Horizontal pictures
import product01 from '../asset/img/h1.jpg';
import product02 from '../asset/img/h2.jpg';
import product03 from '../asset/img/h3.jpg';

function Product() {
   
    return (
        <div >
            <div className="text-center p-4 mb-6 scroll-smooth font-Inter">
                <h1 className="text-3xl font-semibold">GALERIE</h1>
                <p>Produits que vous trouverez dans notre boutique</p>
            </div>

            <Carousel1 />
            <section className="container mx-auto px-4 md:px-0 lg:px-24 bg-white dark:bg-gray-900 pt-6">

                <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 sm:grid-cols-2 xl:grid-cols-3 lg:grid-cols-3">
                    <div className="w-78 md:w-96 px-2 mx-auto bg-logoblue rounded-lg shadow-sm">
                        <a href="https://newtibetsouvenirs.com/static/media/h1.8645ba4b22084eacbcc3.jpg" rel='noreferrer' target='_blank'><img className="w-full object-cover object-center rounded-md" src={product01} alt="Product" />  </a>  
                    </div>
                    <div className="w-78 md:w-96 px-2 mx-auto bg-logoblue rounded-lg shadow-sm">
                        <a href="https://newtibetsouvenirs.com/static/media/h2.29c3cffe211392ed37ec.jpg" rel='noreferrer' target='_blank'><img className=" w-full object-cover object-center rounded-md" src={product02} alt="Product" />  </a>  
                    </div>
                    <div className="w-78 md:w-96 px-2 mx-auto h-auto bg-logoblue rounded-lg shadow-sm">
                        <a href="https://newtibetsouvenirs.com/static/media/h3.871fdecf377bf176541a.jpg" target='_blank' rel='noreferrer'><img className="w-full object-cover object-center rounded-md" src={product03} alt="Product" /> </a>   
                    </div>
                </div>   
            </section>
                <hr className="h-px mt-16 mx-24 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <section className="container mx-auto px-4 md:px-24 bg-white dark:bg-gray-900 pt-6 mb-2">
                <div className="grid grid-cols-1 gap-16 mt-8 xl:mt-12 xl:gap-12 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 mb-2">
                    <div className="w-full">
                        <a href="https://newtibetsouvenirs.com/static/media/v1.7ed1f9a120a33f092b88.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product}  /></a>
                    </div>

                    <div className="w-full ">
                        <a href="https://newtibetsouvenirs.com/static/media/v1.7ed1f9a120a33f092b88.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product1} /></a>
                    </div>

                    <div className="w-full">
                        <a href="https://newtibetsouvenirs.com/static/media/v3.cc20a66566e305dcf470.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product2} /></a>
                    </div>

                    <div className="w-full ">
                        <a href="https://newtibetsouvenirs.com/static/media/v4.d0add4474b47b94cd8c8.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product3} /></a>
                    </div>

                    <div className="w-full ">
                        <a href="https://newtibetsouvenirs.com/static/media/v5.c8dc5691cb7683a88c60.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product4} /></a>
                    </div>

                    <div className="w-full ">
                        <a href="https://newtibetsouvenirs.com/static/media/v6.bd7de73d8c7b8b362603.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product5} /></a>
                    </div>

                    <div className="w-full ">
                        <a href="https://newtibetsouvenirs.com/static/media/v7.6614a8ccafe5b3f53f92.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product6} /></a>
                    </div>

                    <div className="w-full ">
                        <a href="https://newtibetsouvenirs.com/static/media/v8.f5afeb521b3176176e07.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product7} /></a>
                    </div>
                    <div className="w-full z-10">
                        <a href="https://newtibetsouvenirs.com/static/media/v9.a81340e69e987827fe86.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product8} /></a>
                    </div>
                    <div className="w-full ">
                        <a href="https://newtibetsouvenirs.com/static/media/v10.421d12786b20cc4b9bfc.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product9} /></a>
                    </div>
                    <div className="w-full ">
                        <a href="https://newtibetsouvenirs.com/static/media/20.4de44d649a80e4a15266.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product10} /></a>
                    </div>
                    <div className="w-full z-10 pb-4">
                        <a href="https://newtibetsouvenirs.com/static/media/21.7be18b3b107aaddd795c.jpg" target='_blank' rel='noreferrer' >
                        <Cards cardImg={product11} /></a>
                    </div>
                </div>   
            </section>
            <img src={Floatlogo} alt="floating logo bg" className='hidden lg:block absolute opacity-60 -mt-80 right-20 w-36 h-auto rotate-90' />
            <svg viewbox="0 0 91 91" className="hidden lg:block absolute left-40 w-32 h-32 -mt-28 z-0 -ml-10 text-logogray
            fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
            cx="38" cy="38" r="38"/></g></g></g></g></svg>
            
        </div>
    )
};
export default Product;