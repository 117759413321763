import urbantibet from '../asset/icons/urbantibet.png'
import mail from '../asset/icons/mail.png';
import phone from '../asset/icons/phone.png';
import insta from '../asset/icons/instagram.png';

const Footer = ()=>{
    return(
        <footer className="text-neutral-900 font-Inter text-sm sticky h-18 overflow-hidden">
            
                <hr className="h-px my-2 bg-gray-200 mx-12 border-0 dark:bg-gray-700"></hr>

                <div className="flex flex-col md:flex-row justify-evenly items-center" >
                    <div style={{'display': 'flex'}}>
                        <a href="https://www.instagram.com/newtibetsouvenirs/" type="button"
                            className="mx-1 h-6 w-8" target="_blank" rel="noreferrer" >
                            <img src={insta} alt="instagram" style={{'width': '24px'}} />
                        </a>

                        <a href="tel:0973178014" type="button" className="mx-1 h-6 w-8"><img src={phone} alt="phone" style={{'width': '24px'}} rel="noreferrer" />
                        </a>
                        <a href="mailto:newtibetsouvenirs@gmail.com" type="button" className="mx-1 h-6 w-8" ><img src={mail} alt="e-mail" rel="noreferrer" style={{'width': '24px'}} />
                        </a>
                    </div>
                <div
                    className="p-2 flex items-center">
                    <p className='uppercase font-light'>Conçu et développé par </p>
                    <a href="https://www.urbantibet.com/french/index.html"><img src={urbantibet} alt='urbantibet' className='w-14 pl-2' /></a>
                </div>
                  
            </div>


            </footer>
    )

} 
export default Footer;