import Carousel from 'react-multi-carousel';
import Card from './cards';
import 'react-multi-carousel/lib/styles.css';
import img1 from '../asset/carouselImg/1.jpg';
import img2 from '../asset/carouselImg/2.jpg';
import img3 from '../asset/carouselImg/3.jpg';
import img4 from '../asset/carouselImg/4.jpg';
import img5 from '../asset/carouselImg/5.jpg';
import img6 from '../asset/carouselImg/6.jpg';
import img7 from '../asset/carouselImg/7.jpg';
import img8 from '../asset/carouselImg/8.jpg';
import img9 from '../asset/carouselImg/9.jpg';
import img10 from '../asset/carouselImg/10.jpg';
import img11 from '../asset/carouselImg/11.jpg';
import img12 from '../asset/carouselImg/12.jpg';
import img13 from '../asset/carouselImg/13.jpg';
import img14 from '../asset/carouselImg/14.jpg';
import img15 from '../asset/carouselImg/15.jpg';
import img16 from '../asset/carouselImg/16.jpg';
import img17 from '../asset/carouselImg/17.jpg';
import img18 from '../asset/carouselImg/18.jpg';

function Carousel1() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
      
return(
        <Carousel responsive={responsive} className='w-full p-4 bg-logoblue shadow-lg'>
            <div><Card cardImg={img1} /></div>
            <div><Card cardImg={img2} /></div>
            <div><Card cardImg={img3} /></div>
            <div><Card cardImg={img4} /></div>
            <div><Card cardImg={img5} /></div>
            <div><Card cardImg={img6} /></div>
            <div><Card cardImg={img7} /></div>
            <div><Card cardImg={img8} /></div>
            <div><Card cardImg={img9} /></div>
            <div><Card cardImg={img10} /></div>
            <div><Card cardImg={img11} /></div>
            <div><Card cardImg={img12} /></div>
            <div><Card cardImg={img13} /></div>
            <div><Card cardImg={img14} /></div>
            <div><Card cardImg={img15} /></div>
            <div><Card cardImg={img16} /></div>
            <div><Card cardImg={img17} /></div>
            <div><Card cardImg={img18} /></div>
        </Carousel>

)

}
export default Carousel1;