import Floatlogo from '../asset/img/two_circle.png';


function Contact() {
   
    return (
    <div >
        <div className=" md:p-14 my-36 md:mb-9 text-gray-800">
           
           <div className="block rounded-lg shadow-lg bg-gray-100 relative z-10 ">
                <div className="flex flex-wrap md:flex-row-reverse justify-evenly items-center p-4 mb-8" >

                <div className="grow-0 shrink-0 basis-auto w-full lg:w-6/12 xl:w-6/12">
                    <div className="flex flex-wrap pt-12 lg:pt-0">
                        <div className="mb-12 grow-0 shrink-0 basis-auto w-full md:w-6/12 lg:w-full xl:w-6/12 px-3 md:px-6 xl:px-12">
                            <div className="flex items-start">
                                <div className="shrink-0">
                                    <div className="p-4 bg-gray-400 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                                        <svg className="w-6 h-6" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                                    </div>
                                </div>
                                <div className="grow ml-4">
                                    <p className="font-bold mb-1">Adresse</p>
                                    <p >30 rue Saint Paul</p>
                                    <p >75004 Paris </p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12 grow-0 shrink-0 basis-auto w-full md:w-6/12 lg:w-full xl:w-6/12 px-3 md:px-6 xl:px-12">
                            <div className="flex items-start">
                                <div className="shrink-0">
                                    <div className="p-4 bg-gray-400 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                                        <svg className="w-6 h-6" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                                    </div>
                                </div>
                                <div className="grow ml-4">
                                    <  p className="font-bold mb-1">Téléphone</p>
                                    <p >09 73 17 80 14</p>
                                    
                                </div>
                                
                            </div>
                        </div>
                        <div className="mb-12 grow-0 shrink-0 basis-auto w-full md:w-6/12 lg:w-full xl:w-6/12 px-3 md:px-6 xl:px-12">
                            < div className="flex align-start">
                                <div className="shrink-0">
                                    <div className="p-4 bg-gray-400 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                                        <svg className="w-6 h-6" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z" clipRule="evenodd"></path></svg>
                                    </div>
                                </div>
                                <div className="grow ml-4">
                                    <p className="font-bold mb-1">Email</p>
                                    <p >newtibetsouvenirs@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12 lg:mb-0 grow-0 shrink-0 basis-auto w-full md:w-6/12 lg:w-full xl:w-6/12 px-3 md:px-6 xl:px-12">
                            <div className="flex align-start">
                                <div className="shrink-0">
                                    <div className="p-4 bg-gray-400 rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                                        <svg className="w-6 h-6" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd"></path></svg>
                                    </div>
                                </div>
                                <div className="grow ml-4">
                                    <p className="font-bold mb-1">Horaires </p>
                                    <p> 12h00 - 19h30</p>
                                    <p> Lundi - Fermé</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="grow-0 shrink-0 basis-auto block w-full lg:flex lg:w-6/12 xl:w-6/12">
                    <div className="map-container-2 w-full h-96">
                        <iframe title="location of new tibet souvenirs in google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.2251819722023!2d2.359697076233243!3d48.85391627133162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e671fe66148e07%3A0xe6cd62100491dd7f!2s30%20Rue%20Saint-Paul%2C%2075004%20Paris!5e0!3m2!1sfr!2sfr!4v1685793725635!5m2!1sfr!2sfr" className="left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"  allowfullscreen frame-border="0" ></iframe>
                        </div>
                    </div>
            </div>

        </div>
            <svg viewbox="0 0 91 91" className="hidden md:block absolute top-60 left-72 w-32 h-32 z-0 text-logogray
        fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
         cx="36" cy="36" r="36"/></g></g></g></g></svg>

        <img src={Floatlogo} alt="floating logo bg" className='hidden md:block absolute opacity-60 rotate-180 xl:rotate-90 bottom-2 lg:bottom-48 right-0 z-0 w-32 h-auto' />
      
    </div>
        
        </div>
    )
};
export default Contact;